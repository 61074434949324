<template>
  <v-card flat>
    <template>
      <v-layout class="my-auto">
        <barcode-scanner v-if="payload.showScanCode" v-model="search" :search="search" :scannerPayload="scannerPayload" :loading="scannerFieldLoading"></barcode-scanner>
        <v-text-field v-else solo :label="$t('message.common.search')" class="pa-0" v-model="search" hide-details dense></v-text-field>
      </v-layout>
      <v-card-title class="pa-0 pl-1 mt-1">
        <v-btn color="primary" v-if="payload.addBtnTo && checkActionPermission(payload.module, CONSTANTS.CREATE)" fab x-small id="newbtnTo" :to="payload.addBtnTo" class="ml-3">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <h5>{{ payload.isCustomModules ? payload.moduleTitle : $t(payload.moduleTitle) }}</h5>
        <v-spacer></v-spacer>
        <h6 class="mr-4">{{payload.list.length}} {{ $t('message.common.of') }} {{payload.total}}</h6>
      </v-card-title>
    </template>
    <v-card-text class="pa-0 mt-2 mb-4">
      <v-virtual-scroll
        v-show="payload.list.length"
        :items="payload.list"
        :item-height="payload.width"
        max-height="65vh"
        :id="id"
        :key="key"
      >
        <template v-slot="{ item }">
          <v-card outlined color="#e8eaf6" class="mt-1 pa-1" @click="isList ? navigateOrder(item.id) : false">
            <v-card-text class="pa-1 pt-0 pb-0">
              <v-row>
                <v-col cols="3" class="pa-0 my-auto">
                  <span class="body-2">{{ item.number }}</span>
                </v-col>
                <v-col cols="7" class="pa-0">
                  <v-row class="mt-2">
                    <v-col cols="12" class="pa-0">
                      <template v-if="type === 'supplier'">
                        {{ item.suppliername }}
                      </template>
                      <template v-else-if="type === 'transfer'">
                        S: {{ item.sourcewarehouse }}
                      </template>
                      <template v-else-if="type === 'adjustment'">
                        W: {{ item.warehousename }}
                      </template>
                    </v-col>
                    <v-col cols="12" class="pa-0" v-if="type === 'transfer'">
                      D: {{ item.destwarehouse }}
                    </v-col>
                    <v-col cols="7" class="pa-0">
                      O: {{ item.orderdate | dateFormat }}
                    </v-col>
                    <v-col cols="5" class="pa-0 text-end pr-2" v-if="isList && (type === 'supplier' || type === 'adjustment')">
                      {{ $t('message.product.qty') }}:
                      <template v-if="type === 'supplier'">{{ item.receivedqty | absoluteNumber }}</template>
                      <template v-else-if="type === 'adjustment'">{{ item.qty | absoluteNumber }}</template>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="pa-0 my-auto text-right">
                  <v-btn icon v-if="isList">
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                  <h4 v-else>
                    +{{ item.receivedqty | absoluteNumber }}
                  </h4>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          v-if="payload.loading"
          height="6"
        ></v-progress-linear>
        <v-progress-circular
          indeterminate
          color="primary"
          class="mx-auto"
          v-if="false"
        ></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!payload.loading && (payload.list.length === 0)" item.type="info" class="mt-2" >
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import PermissionsMixin from '@/mixins/permissions'
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
export default {
  mixins: [PermissionsMixin],
  props: ['payload', 'id', 'type', 'isList'],
  data () {
    return {
      search: '',
      key: 0,
      debouncedSearch: null,
      bottom: false,
      scannerPayload: {
        placeholder: 'message.whsDashboard.scanText',
        change: this.getRespectiveOrders,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      scannerFieldLoading: false
    }
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId']),
    paginationInfo () {
      return this.recordsInfo
    }
  },
  mounted () {
    // search after some ms
    this.debouncedSearch = this.$formatter.debounce(() => {
      this.$eventBus.$emit('loadList', this.search)
    }, 750)
    // check if id loaded then do the below
    const el = document.getElementById(this.id)
    if (el && !this.payload.listenerAdded) {
      el.addEventListener('scroll', () => {
        this.bottom = this.bottomVisible()
      })
      this.payload.listenerAdded = true
    }
  },
  methods: {
    bottomVisible () {
      const el = document.getElementById(this.id)
      const scrollY = el.scrollTop
      const visible = el.clientHeight
      const pageHeight = el.scrollHeight
      const bottomOfPage = visible + scrollY + 1 >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    /* For equipment list scan */
    getRespectiveOrders () {
      if (this.search) {
        this.scannerFieldLoading = true
        hostAppApi.get(`${this.getHostRefApi}warehouseorders/get_order_by_number/${this.search.split('?')[0]}?type=${this.type}`)
          .then((response) => {
            if (response && response.data && response.data !== '\n') {
              if (this.type === 'adjustment') this.$router.push(`/adjustment_orders/${response.data}`)
              else if (this.type === 'supplier') this.$router.push(`/supplier_orders/${response.data}`)
              else if (this.type === 'transfer') this.$router.push(`/transfer_orders/${response.data}`)
            } else {
              this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: this.$t('message.order.noOrderFound') })
            }
          })
          .finally(() => { this.scannerFieldLoading = false })
      }
    },
    navigateOrder (id) {
      let url = ''
      switch (this.type) {
        case 'supplier':
          url = 'supplier_orders'
          break
        case 'transfer':
          url = 'transfer_orders'
          break
        case 'adjustment':
          url = 'adjustment_orders'
          break
        default:
          break
      }
      this.$router.push(`/${url}/${id}`)
    }
  },
  watch: {
    search (val) {
      if (!this.payload.showScanCode) this.debouncedSearch()
    },
    bottom (val) {
      if (val) {
        if (this.payload.list.length !== this.payload.total) this.$eventBus.$emit('loadList', this.search)
      }
    }
  },
  beforeDestroy () {}
}
</script>
<style scoped>
  .custom-height {
    position: relative;
    width: 100%;
    max-height: 5.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px;
  }
</style>
